
@media (max-width: 1000px) {
    .hide-cell {
        display: none!important;

    }
  }

  .hidden {
      display: none!important
  }

  .show {
      display: block!important
  }